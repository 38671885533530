import React, { useEffect } from "react";
import Modal from "react-modal";
import ReactMarkdown from "react-markdown";
import "../index.css";

const TermsAndConditions = ({ isOpen, closeModal }) => {
  const markdownContent = `
  # Brainbook.link Terms and Conditions

  These terms and conditions (“Terms”) govern your use of brainbook.link (“Website”) and the purchase of any paid products or services provided by the Website. By using the Website or purchasing any products or services, you agree to be bound by these Terms. If you do not agree with these Terms, you should not use the Website or purchase any products or services.
  
  ## User Conduct
  
  You are responsible for your conduct on the Website. You agree to use the Website only for lawful purposes and in a manner that does not infringe on the rights of, or restrict or inhibit the use and enjoyment of the Website by, any third party. Prohibited conduct includes, but is not limited to:
  
  - Engaging in any activity that is illegal or violates any applicable law or regulation;
  - Posting, transmitting, or otherwise making available any content that is harmful, threatening, abusive, harassing, vulgar, obscene, or otherwise objectionable;
  - Impersonating another person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity;
  - Disrupting or interfering with the Website or servers or networks connected to the Website;
  - Collecting or storing personal data about other users of the Website without their express consent;
  - Attempting to gain unauthorized access to the Website or any part of it, or to any system or network connected to the Website, by hacking, password mining, or any other means.
  
  ## Intellectual Property
  
  The Website and its contents are protected by copyright, trademark, and other intellectual property laws. All rights in the Website and its contents are reserved to the Website owner, and no other rights are granted or implied. You agree not to modify, publish, transmit, distribute, perform, or create derivative works of any content on the Website without the express written consent of the Website owner.
  
  ## Disallowed usage of Brainbook.link models
  
  We don’t allow the use of Brainbook.link models for the following:
  
  - Illegal activity
  - Child Sexual Abuse Material or any content that exploits or harms children
  - Generation of hateful, harassing, or violent content
  - Generation of malware
  - Activity that has high risk of physical harm
  - Activity that has high risk of economic harm
  - Fraudulent or deceptive activity
  - Adult content, adult industries, and dating apps
  - Political campaigning or lobbying
  - Activity that violates people’s privacy
  - Engaging in the unauthorized practice of law, or offering tailored legal advice without a qualified person reviewing the information
  - Offering tailored financial advice without a qualified person reviewing the information
  - Telling someone that they have or do not have a certain health condition, or providing instructions on how to cure or treat a health condition
  - High risk government decision-making
  
  ## Disclaimer of Warranties
  
  The Website and any products or services provided by the Website are provided on an “as is” basis without warranties of any kind, either express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, or non-infringement. The Website owner does not warrant that the Website or any products or services provided by the Website will be uninterrupted or error-free, or that the Website or server that makes it available are free of viruses or other harmful components.
  
  ## Limitation of Liability
  
  In no event shall the Website owner be liable for any damages, including, without limitation, direct, indirect, incidental, special, consequential, or punitive damages arising out of the use or inability to use the Website or any products or services provided by the Website, even if the Website owner has been advised of the possibility of such damages. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you.
  
  ## Indemnification
  
  You agree to indemnify and hold harmless the Website owner from any and all claims, damages, expenses, and liabilities (including reasonable attorneys’ fees) arising out of or in connection with your use of the Website or any products or services provided by the Website, or your violation of these Terms.
  
  ## Governing Law
  
  These Terms shall be governed by and construed in accordance with the laws of the state or country in which the Website owner is located, without giving effect to any principles of conflicts of law. Any dispute arising out of or related to these Terms or the Website shall be brought in the courts located in the state or country in which the Website owner is located.
  
  ## Changes to Terms
  
  The Website owner reserves the right to modify these Terms at any time without prior notice. Your continued use of the Website or purchase of any products or services after such modifications will constitute your acceptance of the modified Terms.
  
  ## GDPR Related Content
  
  The Website owner is committed to protecting the privacy of its users and complying with applicable data protection laws, including the EU General Data Protection Regulation (GDPR). 
  
  For more information on how the Website owner collects, uses, and discloses personal data, please refer to the Website’s Privacy Policy.
  
  ## Terms and Conditions of Sale
  
  ### Paid Products
  
  Some of the Products provided on this Application, as part of the Service, are provided on the basis of payment.
  
  The fees, duration, and conditions applicable to the purchase of such Products are described below and in the dedicated sections of this Application.
  
  ### Product Description
  
  Prices, descriptions or availability of Products are outlined in the respective sections of this Application and are subject to change without notice.
  
  While Products on this Application are presented with the greatest accuracy technically possible, representation on this Application through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.
  
  The characteristics of the chosen Product will be outlined during the purchasing process.
  
  ### Purchasing Process
  
  Any steps taken from choosing a Product to order submission form part of the purchasing process.
  
  The purchasing process includes these steps:
  
  - Users must choose the desired Product and verify their purchase selection.
  - After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.
  
  ### Order Submission
  
  When the User submits an order, the following applies:
  
  - The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.
  - In case the purchased Product requires active input from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.
  - Upon submission of the order, Users will receive a receipt confirming that the order has been received.
  
  All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.
  
  ### Prices
  
  Users are informed during the purchasing process and before order submission about any fees, taxes, and costs (including, if any, delivery costs) that they will be charged.
  
  Prices on this Application are displayed:
  
  - excluding any applicable fees, taxes, and costs;
  
  ### Offers and Discounts
  
  The Owner may offer discounts or provide special offers for the purchase of Products. Any such offer or discount shall always be subject to eligibility criteria and the terms and conditions set out in the corresponding section of this Application.
  
  Offers and discounts are always granted at the Owner’s sole discretion.
  
  Repeated or recurring offers or discounts create no claim/title or right that Users may enforce in the future.
  
  Depending on the case, discounts or offers shall be valid for a limited time only or while stocks last. If an offer or discount is limited by time, the time indications refer to the time zone of the Owner, as indicated in the Owner’s location details in this document, unless otherwise specified.
  
  ### Coupons
  
  Offers or discounts can be based on Coupons.
  
  If a breach of the conditions applicable to Coupons occurs, the Owner can legitimately refuse to fulfill its contractual obligations and expressly reserves the right to take appropriate legal action to protect its rights and interests.
  
  Notwithstanding the provisions below, any additional or diverging rules applicable to using the Coupon displayed in the corresponding information page or on the Coupon itself shall always prevail.
  
  Unless otherwise stated, these rules apply to the use of Coupons:
  
  - Each Coupon is only valid when used in the manner and within the timeframe specified on the website and/or the Coupon;
  - A Coupon may only be applied, in its entirety, at the actual time of purchase - partial use is not permitted;
  - Unless otherwise stated, single-use Coupons may only be used once per purchase and therefore may only be applied a single time even in cases involving installment-based purchases;
  - A Coupon cannot be applied cumulatively;
  - The Coupon must be redeemed exclusively within the time specified in the offer. After this period, the Coupon will automatically expire, precluding any possibility for the User to claim the relevant rights, including cash-out;
  - The User is not entitled to any credit/refund/compensation if there is a difference between the value of the Coupon and the redeemed value;
  - The Coupon is intended solely for non-commercial use. Any reproduction, counterfeiting and commercial trade of the Coupon is strictly forbidden, along with any illegal activity related to the purchase and/or use of the Coupon.
  
  ### Methods of Payment
  
  Information related to accepted payment methods is made available during the purchasing process.
  
  Some payment methods may only be available subject to additional conditions or fees. In such cases, related information can be found in the dedicated section of this Application.
`;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "600px",
      maxHeight: "80vh",
      overflow: "auto",
      padding: "20px",
      borderRadius: "20px", // Add rounded corners
      border: "1px solid #ccc", // Add border
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)", // Add shadow
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Add overlay color
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={true}
    >
      <button className="close-button" onClick={closeModal}>
        X
      </button>
      <ReactMarkdown className="message__markdown">
        {markdownContent}
      </ReactMarkdown>
    </Modal>
  );
};

export default TermsAndConditions;
