import { useState } from "react";
import { runUpdate_Address } from "../services/dynamodb/update_address";
import "./AddressFormModal.css";

const AddressFormModal = ({ user_email, onAddressSubmit }) => {
  const [reg_name, setRegName] = useState("");
  const [reg_surname, setRegSurname] = useState("");
  //const [reg_identityNumber, setRegIdentityNumber] = useState("");
  //const [reg_gsmNumber, setRegGsmNumber] = useState("");
  const [reg_address, setRegAddress] = useState("");
  const [reg_city, setRegCity] = useState("");
  const [reg_country, setRegCountry] = useState("");
  const [reg_zipCode, setRegZipCode] = useState("");
  const [ship_contactName, setShipContactName] = useState("");
  const [ship_address, setShipAddress] = useState("");
  const [ship_city, setShipCity] = useState("");
  const [ship_country, setShipCountry] = useState("");
  const [ship_zipCode, setShipZipCode] = useState("");
  const [bill_contactName, setBillContactName] = useState("");
  const [bill_address, setBillAddress] = useState("");
  const [bill_city, setBillCity] = useState("");
  const [bill_country, setBillCountry] = useState("");
  const [bill_zipCode, setBillZipCode] = useState("");
  const [sameAddress, setSameAddress] = useState(true);
  const [sameRegAddress, setSameRegAddress] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const randomNumber = Math.floor(Math.random() * 90000000000) + 10000000000;
    // generates a random 11 digit number between 10000000000 and 99999999999
    await runUpdate_Address(
      process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
      user_email,
      reg_name,
      reg_surname,
      randomNumber.toString(), //reg_identityNumber
      "", //reg_gsmNumber
      reg_address,
      reg_city,
      reg_country,
      reg_zipCode,
      sameRegAddress ? reg_name + " " + reg_surname : ship_contactName,
      sameRegAddress ? reg_address : ship_address,
      sameRegAddress ? reg_city : ship_city,
      sameRegAddress ? reg_country : ship_country,
      sameRegAddress ? reg_zipCode : ship_zipCode,
      sameAddress ? reg_name + " " + reg_surname : bill_contactName,
      sameAddress ? reg_address : bill_address,
      sameAddress ? reg_city : bill_city,
      sameAddress ? reg_country : bill_country,
      sameAddress ? reg_zipCode : bill_zipCode
    );
    onAddressSubmit(); // close the modal and continue with the payment process
  };

  const handleSameAddressChange = (e) => {
    if (e.target.id === "sameRegAddress") {
      setSameRegAddress(e.target.checked);
      if (e.target.checked) {
        // copy registration address to shipping address
        setShipContactName(reg_name + " " + reg_surname);
        setShipAddress(reg_address);
        setShipCity(reg_city);
        setShipCountry(reg_country);
        setShipZipCode(reg_zipCode);
      } else {
        // clear shipping address
        setShipContactName("");
        setShipAddress("");
        setShipCity("");
        setShipCountry("");
        setShipZipCode("");
      }
    } else if (e.target.id === "sameAddress") {
      setSameAddress(e.target.checked);
      if (e.target.checked) {
        // copy shipping address to billing address
        setBillContactName(reg_name + " " + reg_surname);
        setBillAddress(ship_address);
        setBillCity(ship_city);
        setBillCountry(ship_country);
        setBillZipCode(ship_zipCode);
      } else {
        // clear billing address
        setBillContactName("");
        setBillAddress("");
        setBillCity("");
        setBillCountry("");
        setBillZipCode("");
      }
    }
  };

  return (
    <div className="address-form-container">
      <h2 className="address-form-title">Address Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="registration-address">
          <h3>Registration address</h3>
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_name">
              Your Name*
            </label>
            <input
              type="text"
              id="reg_name"
              className="form-control my-input-style"
              value={reg_name}
              onChange={(e) => setRegName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_surname">
              Your Lastname*
            </label>
            <input
              type="text"
              id="reg_surname"
              className="form-control my-input-style"
              value={reg_surname}
              onChange={(e) => setRegSurname(e.target.value)}
              required
            />
          </div>
          {/*           <div className="form-group">
            <label className="my-label-style" htmlFor="reg_identityNumber">Identity Number*</label>
            <input
              type="text"
              id="reg_identityNumber"
              className="form-control my-input-style"
              value={reg_identityNumber}
              onChange={(e) => setRegIdentityNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_gsmNumber">Phone Number*</label>
            <input
              type="text"
              id="reg_gsmNumber"
              className="form-control my-input-style"
              value={reg_gsmNumber}
              onChange={(e) => setRegGsmNumber(e.target.value)}
              required
            />
          </div> */}
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_address">
              Address*
            </label>
            <input
              type="text"
              id="reg_address"
              className="form-control my-input-style"
              value={reg_address}
              onChange={(e) => setRegAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_zipCode">
              Zip/Postal code*
            </label>
            <input
              type="text"
              id="reg_zipCode"
              className="form-control my-input-style"
              value={reg_zipCode}
              onChange={(e) => setRegZipCode(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_city">
              City*
            </label>
            <input
              type="text"
              id="reg_city"
              className="form-control my-input-style"
              value={reg_city}
              onChange={(e) => setRegCity(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="my-label-style" htmlFor="reg_country">
              Country*
            </label>
            <input
              type="text"
              id="reg_country"
              className="form-control my-input-style"
              value={reg_country}
              onChange={(e) => setRegCountry(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="shipping-address">
          <h3>Shipping address</h3>
          <div className="form-group">
            <input
              type="checkbox"
              id="sameRegAddress"
              checked={sameRegAddress}
              onChange={handleSameAddressChange}
            />
            <label className="my-label-style" htmlFor="sameRegAddress">
              Same as registration address
            </label>
          </div>
          {!sameRegAddress && (
            <>
              <div className="form-group">
                <label className="my-label-style" htmlFor="ship_contactName">
                  Contact name*
                </label>
                <input
                  type="text"
                  id="ship_contactName"
                  className="form-control my-input-style"
                  value={ship_contactName}
                  onChange={(e) => setShipContactName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="ship_address">
                  Address*
                </label>
                <input
                  type="text"
                  id="ship_address"
                  className="form-control my-input-style"
                  value={ship_address}
                  onChange={(e) => setShipAddress(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="ship_zipCode">
                  Zip/Postal code*
                </label>
                <input
                  type="text"
                  id="ship_zipCode"
                  className="form-control my-input-style"
                  value={ship_zipCode}
                  onChange={(e) => setShipZipCode(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="ship_city">
                  City*
                </label>
                <input
                  type="text"
                  id="ship_city"
                  className="form-control my-input-style"
                  value={ship_city}
                  onChange={(e) => setShipCity(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="ship_country">
                  Country*
                </label>
                <input
                  type="text"
                  id="ship_country"
                  className="form-control my-input-style"
                  value={ship_country}
                  onChange={(e) => setShipCountry(e.target.value)}
                  required
                />
              </div>
            </>
          )}
        </div>
        <div className="billing-address">
          <h3>Billing address</h3>
          <div className="form-group">
            <input
              type="checkbox"
              id="sameAddress"
              checked={sameAddress}
              onChange={handleSameAddressChange}
            />
            <label className="my-label-style" htmlFor="sameAddress">
              Same as shipping address
            </label>
          </div>
          {!sameAddress && (
            <>
              <div className="form-group">
                <label className="my-label-style" htmlFor="bill_contactName">
                  Contact name*
                </label>
                <input
                  type="text"
                  id="bill_contactName"
                  className="form-control my-input-style"
                  value={bill_contactName}
                  onChange={(e) => setBillContactName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="bill_address">
                  Address*
                </label>
                <input
                  type="text"
                  id="bill_address"
                  className="form-control my-input-style"
                  value={bill_address}
                  onChange={(e) => setBillAddress(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="bill_zipCode">
                  Zip/Postal code*
                </label>
                <input
                  type="text"
                  id="bill_zipCode"
                  className="form-control my-input-style"
                  value={bill_zipCode}
                  onChange={(e) => setBillZipCode(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="bill_city">
                  City*
                </label>
                <input
                  type="text"
                  id="bill_city"
                  className="form-control my-input-style"
                  value={bill_city}
                  onChange={(e) => setBillCity(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label className="my-label-style" htmlFor="bill_country">
                  Country*
                </label>
                <input
                  type="text"
                  id="bill_country"
                  className="form-control my-input-style"
                  value={bill_country}
                  onChange={(e) => setBillCountry(e.target.value)}
                  required
                />
              </div>
            </>
          )}
        </div>
        <button type="submit" className="btn btn-primary">
          Continue to the payment form
        </button>
      </form>
    </div>
  );
};

export default AddressFormModal;
