import React, {useEffect} from 'react';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import '../index.css';

const PrivacyPolicy = ({ isOpen, closeModal }) => {

  const markdownContent = `
  # Privacy Policy for Brainbook.link

  At Brainbook.link, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your information when you use our website.
  
  ## Information we collect
  When you visit our website, we may collect certain information about you, including:
  
  - Personal information, such as your name, email address, and other contact details you provide when you register for an account or submit a contact form.
  - Information about your device, such as your IP address, browser type, and operating system.
  - Usage information, such as the pages you visit on our website, the time and date of your visit, and the duration of your visit.
  - Cookies and other tracking technologies, which allow us to personalize your experience and improve our website’s functionality.
  
  ## How we use your information
  We use your information to provide and improve our services, including:
  
  - Creating and managing your account.
  - Responding to your inquiries and requests.
  - Personalizing your experience on our website.
  - Conducting research and analysis to improve our website and services.
  - Sending you newsletters, marketing materials, and other communications that may be of interest to you.
  
  We may also disclose your information to third-party service providers who help us operate our website and provide our services. These providers are required to maintain the confidentiality and security of your information and may not use it for any other purpose.
  
  We may also disclose your information if required by law or if we believe that such disclosure is necessary to protect our rights, your safety or the safety of others, investigate fraud, or comply with a judicial proceeding or court order.
  
  ## How we protect your information
  We take reasonable measures to protect your information from unauthorized access, accidental loss, or disclosure. We use industry-standard security technologies and procedures to safeguard your information.
  
  However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.
  
  ## Your rights and choices
  You have the right to access, modify, or delete your personal information at any time. You can do so by logging into your account or contacting us at brainbook@brainbook.link
  
  You can also opt-out of receiving marketing communications from us by clicking the “unsubscribe” link in the email or contacting us at brainbook@brainbook.link
  
  ## Third-party links and services
  Our website may contain links to third-party websites or services. These websites or services are not under our control, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of these third-party websites or services before using them.
  
  ## Changes to this Privacy Policy
  We may update this Privacy Policy from time to time. The revised policy will be posted on our website, and we will notify you of any material changes via email or through a notice on our website.
  
  ## Contact us
  If you have any questions or concerns about this Privacy Policy or our practices, please contact us at brainbook@brainbook.link
`;

   useEffect(() => {
     if (isOpen) {
       document.body.classList.add('modal-open');
     } else {
       document.body.classList.remove('modal-open');
     }
   }, [isOpen]);

   const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '600px',
      maxHeight: '80vh',
      overflow: 'auto',
      padding: '20px',
      borderRadius: '20px', // Add rounded corners
      border: '1px solid #ccc', // Add border
      boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)', // Add shadow
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Add overlay color
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} shouldCloseOnOverlayClick={true}>
      <button className="close-button" onClick={closeModal}>X</button>
      <ReactMarkdown className="message__markdown">{markdownContent}</ReactMarkdown>
    </Modal>
  );
};

export default PrivacyPolicy;