import { ExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { ddbDocClient } from "./libs/ddbDocClient";

export const runGet_Address = async (tableName, email) => {
  const params = {
    Statement: `SELECT * FROM ${tableName} WHERE email = '${email}'`,
  };

  try {
    const data = await ddbDocClient.send(new ExecuteStatementCommand(params));
    const item = data.Items[0];
    return {
      reg_name: item.reg_name,
      reg_surname: item.reg_surname,
      reg_identityNumber: item.reg_identityNumber,
      reg_gsmNumber: item.reg_gsmNumber,
      reg_address: item.reg_address,
      reg_city: item.reg_city,
      reg_country: item.reg_country,
      reg_zipCode: item.reg_zipCode,
      ship_contactName: item.ship_contactName,
      ship_address: item.ship_address,
      ship_city: item.ship_city,
      ship_country: item.ship_country,
      ship_zipCode: item.ship_zipCode,
      bill_contactName: item.bill_contactName,
      bill_address: item.bill_address,
      bill_city: item.bill_city,
      bill_country: item.bill_country,
      bill_zipCode: item.bill_zipCode,
    };
  } catch (err) {
    //console.error(err);
  }
};
