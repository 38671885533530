import { useState, useEffect } from "react";

const MAX_MESSAGES_LIMIT =
  process.env.REACT_APP_MAX_LOCALSTORAGE_MESSAGES_LIMIT;

const useMessageCollection = () => {
  const initialMsg = {
    id: 1,
    createdAt: Date.now(),
    text: `**Hi!** *Ask me anything!* `,
    ai: true,
  };
  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem("messages");
    if (storedMessages) {
      return JSON.parse(storedMessages);
    } else {
      return [initialMsg];
    }
  });

  useEffect(() => {
    localStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  const addMessage = (message) => {
    setMessages((prev) => {
      const newMessages = [...prev, message];
      if (newMessages.length > MAX_MESSAGES_LIMIT) {
        newMessages.shift();
      }
      return newMessages;
    });
  };

  const clearMessages = () => setMessages([initialMsg]);

  return [messages, addMessage, clearMessages];
};

export default useMessageCollection;
