import { useState } from "react";

export const paramChatGPTCredits = [];
export const paramDalleCredits = [];
export const paramSubscriptionPlan = [];

export const useUpdatedChatGPTCredits = () => {
  const [updatedChatGPTCredits, setUpdatedChatGPTCredits] =
    useState(paramChatGPTCredits);

  //console.log(updatedChatGPTCredits);
  return { updatedChatGPTCredits, setUpdatedChatGPTCredits };
};

export const useUpdatedDalleCredits = () => {
  const [updatedDalleCredits, setUpdatedDalleCredits] =
    useState(paramDalleCredits);

  //console.log(updatedDalleCredits);
  return { updatedDalleCredits, setUpdatedDalleCredits };
};

export const useUpdatedSubscriptionPlan = () => {
  const [updatedSubscriptionPlan, setUpdatedSubscriptionPlan] = useState(
    paramSubscriptionPlan
  );

  //console.log(updatedSubscriptionPlan);
  return { updatedSubscriptionPlan, setUpdatedSubscriptionPlan };
};
