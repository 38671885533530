import { Configuration, OpenAIApi } from "openai";
import React, { useState, useRef, useEffect, useContext } from "react";
import ChatMessage from "./ChatMessage";
import { ChatContext } from "../context/chatContext";
import Thinking from "./Thinking";
import { runGet } from "../services/dynamodb/get_ddb";
import { runUpdate } from "../services/dynamodb/update_ddb";
import {
  paramChatGPTCredits,
  paramDalleCredits,
  paramSubscriptionPlan,
} from "../components/SideBar_CreditsUpdate";

/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */
const ChatView = ({ selected, user_email }) => {
  const messagesBeginRef = useRef();
  const messagesEndRef = useRef();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState("");
  const [thinking, setThinking] = useState(false);
  const [messages, addMessage, clearMessages] = useContext(ChatContext);

  useEffect(() => {
    const fetchData = async () => {
      const tableName = process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1;
      const result = await runGet(tableName, user_email);
      if (result.Items && result.Items.length > 0) {
        const chatgptCredits = Number(result.Items[0].chatgptCredits.N);
        const dalleCredits = Number(result.Items[0].dalleCredits.N);
        const subscriptionPlan = result.Items[0].subscriptionPlan.S;
        paramChatGPTCredits.current = chatgptCredits;
        paramDalleCredits.current = dalleCredits;
        paramSubscriptionPlan.current = subscriptionPlan;
      } else {
        //console.error(`No data found for user email '${user_email}'`);
      }
    };
    fetchData();
  }, [user_email]);

  /**
   * Scrolls the chat area to the bottom.
   */
  //const scrollToBottom = () => {
  //  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //};

  /**
   * Scrolls the chat area to the top.
   */
  const scrollToTop = () => {
    messagesBeginRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * Adds a new message to the chat.
   *
   * @param {string} newValue - The text of the new message.
   * @param {boolean} [ai=false] - Whether the message was sent by an AI or the user.
   */
  const updateMessage = (newValue, ai = false, selected) => {
    const id = Date.now() + Math.floor(Math.random() * 1000000);
    const newMsg = {
      id: id,
      createdAt: Date.now(),
      text: newValue,
      ai: ai,
      selected: `${selected}`,
    };

    addMessage(newMsg);
  };

  /**
   * Sends our prompt to our API and get response to our request from openai.
   *
   * @param {Event} e - The submit event of the form.
   */
  const sendMessage = async (e) => {
    e.preventDefault();

    const newMsg = formValue;
    const aiModel = selected;

    // Check if user has any remaining credits
    if (
      (aiModel === "GPT" && Number(paramChatGPTCredits.current) === 0) ||
      (aiModel === "DALL·E" && Number(paramDalleCredits.current) === 0)
    ) {
      window.alert(
        `You have consumed all your ${aiModel} credits. To continue using it, please consider using our paid plan.`
      );
      return;
    }

    //console.log("aiModel: " + selected);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let PATH = "";
    switch (aiModel) {
      case "Babbage":
        PATH = "babbage";
        break;
      case "ChatGPT":
        PATH = "davinci";
        break;
      case "DALL·E":
        PATH = "dalle";
        break;
      default:
        PATH = "babbage";
        break;
    }

    const POST_URL = BASE_URL + PATH;

    //console.log(POST_URL)

    setThinking(true);
    setFormValue("");

    let response = [];

    try {
      // Moderation request
      const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      });
      const openai = new OpenAIApi(configuration);
      const moderationResponse = await openai.createModeration({
        input: newMsg,
      });

      //console.log(moderationResponse);

      const inappropriateCategories = Object.keys(
        moderationResponse.data.results[0].categories
      ).filter(
        (category) => moderationResponse.data.results[0].categories[category]
      );

      if (inappropriateCategories.length > 0) {
        // Inappropriate content detected
        window.alert(
          `Inappropriate content detected. Categories: ${inappropriateCategories.join(
            ", "
          )}`
        );
        setThinking(false);
        return;
      }
      updateMessage(newMsg, false, aiModel);
      // AI request
      response = await Promise.race([
        fetch(POST_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors", // Add this line to enable CORS
          body: JSON.stringify({
            prompt: newMsg,
            messages: JSON.parse(localStorage.getItem("messages")),
            user: user_email,
          }),
        }),
        new Promise(
          (_, reject) => setTimeout(() => reject(new Error("timeout")), 35000) // 35 second timeout
        ),
      ]);
    } catch (error) {
      window.alert(
        "Oops! Something went wrong.\nThe request timed out or an error occurred. Please try again."
      );
      console.log(error);
      setThinking(false);
    }

    const data = await response.json();

    //console.log(response.status);
    if (response.ok) {
      // The request was successful

      const tableName = process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1;
      const subscriptionType = "Free";
      const result = await runGet(tableName, user_email);

      const chatgptCredits = Number(result.Items[0].chatgptCredits.N);
      const dalleCredits = Number(result.Items[0].dalleCredits.N);

      if (selected === "ChatGPT" && chatgptCredits >= 0) {
        // Decrease chatgptCredits by 1 in the DynamoDB table
        const updatedChatGPTCredits = chatgptCredits - 1;
        paramChatGPTCredits.current = updatedChatGPTCredits;

        if (updatedChatGPTCredits < 0) {
          window.alert(
            "You have consumed all your ChatGPT credits. To continue using it, please consider using our paid plan."
          );
          clearMessages();
          updateMessage(
            "*You have consumed all your ChatGPT credits. To continue using it, please consider using our paid plan.*",
            true,
            aiModel
          );
          setThinking(false);
          await runUpdate(
            tableName,
            user_email,
            updatedChatGPTCredits,
            dalleCredits,
            subscriptionType
          );
          return;
        } else {
          await runUpdate(
            tableName,
            user_email,
            updatedChatGPTCredits,
            dalleCredits,
            subscriptionType
          );
        }
      } else if (selected === "DALL·E" && dalleCredits >= 0) {
        // Decrease dalleCredits by 1 in the DynamoDB table
        const updatedDalleCredits = dalleCredits - 1;
        paramDalleCredits.current = updatedDalleCredits;

        if (updatedDalleCredits < 0) {
          window.alert(
            "You have consumed all your DALL·E credits. To continue using it, please consider using our paid plan."
          );
          clearMessages();
          updateMessage(
            "*You have consumed all your DALL·E credits. To continue using it, please consider using our paid plan.*",
            true,
            aiModel
          );
          setThinking(false);
          await runUpdate(
            tableName,
            user_email,
            chatgptCredits,
            updatedDalleCredits,
            subscriptionType
          );
          return;
        } else {
          await runUpdate(
            tableName,
            user_email,
            chatgptCredits,
            updatedDalleCredits,
            subscriptionType
          );
        }
      }
      data.bot && updateMessage(data.bot, true, aiModel);
    } else if (response.status === 429) {
      setThinking(false);
    } else {
      // The request failed
      window.alert(`openAI is returning an error: ${
        response.status + response.statusText
      } 
      please try again`);
      console.log(`Request failed with status code ${response.status}`);
      setThinking(false);
    }

    setThinking(false);
  };

  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */
  //useEffect(() => {
  //  scrollToBottom();
  //}, [messages, thinking]);

  /**
   * Scrolls the chat area to the top when the messages array is updated.
   */
  useEffect(() => {
    scrollToTop();
  }, [messages, thinking]);

  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className="chatview" style={{ flexDirection: "column-reverse" }}>
      <form className="form" onSubmit={sendMessage}>
        <textarea
          ref={inputRef}
          className="chatview__textarea-message"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder={`${
            selected === "DALL·E"
              ? "e.g. an armchair in the shape of an avocado"
              : `Type your message here... ${selected} AI Model will reply.`
          }`}
        />
        <button
          type="submit"
          className="chatview__btn-send"
          disabled={!formValue}
        >
          Send
        </button>
      </form>
      <main className="chatview__chatarea">
        <span ref={messagesBeginRef}></span>
        {thinking && <Thinking />}
        {messages
          .slice(0)
          .reverse()
          .map((message, index) => (
            <ChatMessage key={index} message={{ ...message }} />
          ))}
        <span ref={messagesEndRef}></span>
      </main>
    </div>
  );
};

export default ChatView;
