import React, { useState, useEffect } from "react";
import { GiBrain } from "react-icons/gi";

const Thinking = () => {
  const [countdown, setCountdown] = useState(35);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [message, setMessage] = useState(`thinking... ${countdown}s`);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          setShowTryAgain(true);
          return 1;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (showTryAgain) {
      setTimeout(() => {
        setCountdown(30);
        setShowTryAgain(false);
        setMessage(`thinking... ${countdown}s`);
      }, 5000);
    }
  }, [countdown, showTryAgain]);

  useEffect(() => {
    if (countdown === 0) {
      setMessage("thinking...");
    } else {
      setMessage(`thinking... ${countdown}s`);
    }
  }, [countdown]);

  return (
    <div className="message">
      <div className="message__wrapper flex">
        <div className="message__pic">
          <GiBrain />
        </div>
        <div className="text-left message__createdAt">
          <div
            className="message__thinking"
            style={{ color: "#FFA500", fontWeight: "bold" }}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thinking;
