//npm install aws-sdk aws-amplify @aws-amplify/ui-react

//- `YOUR_AWS_PROJECT_REGION`: the region where your resources are located, such as `us-east-1`
//- `YOUR_AWS_COGNITO_REGION`: the region where your Cognito user pool is located, such as `us-east-1`
//- `YOUR_AWS_USER_POOLS_ID`: the ID of your Cognito user pool, such as `us-east-1_ABCDEFGH`
//- `YOUR_AWS_USER_POOLS_WEB_CLIENT_ID`: the ID of your Cognito user pool client, such as `abcdefgh1234567890`
//- `YOUR_COGNITO_DOMAIN`: the domain name of your Cognito user pool, such as `myapp.auth.us-east-1.amazoncognito.com`

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9dv3dsGTC",
    "aws_user_pools_web_client_id": "5ure8mh0s8na2nsrn94kba3hcb",
    "oauth": {
      "domain": "brainbook.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "openid",
      ],
      "redirectSignIn": "https://www.brainbook.link/",
      "redirectSignOut": "https://www.brainbook.link/",
      "responseType": "code",
      "googleClientId": "1092714344316-ut77vpo0v0q4n8vq81a46tb4lleepmi6.apps.googleusercontent.com"
    }
  };
  
  export default awsmobile;

/*
NOTE: THERE IS A TRAILING SLASH "/" AT THE END OF LINKS!

      "redirectSignIn": "https://www.brainbook.link/",
      "redirectSignOut": "https://www.brainbook.link/",

ALSO IN COGNITO, YOU MUST DEFINE IT WITH A TRAILING SLASH "/" AT THE END OF LINKS

COGNITO:
App client: brainbook.link --> Under "Hosted UI":

Allowed callback URLs
https://brainbook.auth.us-east-1.amazoncognito.com/oauth2/idpresponse
https://www.brainbook.link/

Allowed sign-out URLs
https://www.brainbook.link/

*/

/*
GMAIL Authentication
1. https://console.cloud.google.com/apis/credentials
2. Create Project: brainbook
3. Create Consent Screen -> OAuth consent screen
4. Create Credentials -> OAuth client ID
5. Application Type: Web Application


Client ID: 1092714344316-ut77vpo0v0q4n8vq81a46tb4lleepmi6.apps.googleusercontent.com
Client secret: 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

OAuth 2.0 Client IDs -> brainbook

Authorized redirect URIs:
URL1: https://brainbook.auth.us-east-1.amazoncognito.com/oauth2/idpresponse
URL2: https://www.brainbook.link

Google: Verify your website ownership through Search Console
https://www.google.com/webmasters/tools/home
TXT Entry:
google-site-verification=TNvTdDchPgIB-zJ2SIlTY_fhpwQHEZASEqmDWEUj_YA


*/