import React, { useState, useEffect } from 'react'
import { MdComputer, MdPersonOutline, MdContentCopy } from 'react-icons/md'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'
import moment from 'moment'
import Image from './Image'

/**
 * A chat message component that displays a message with a timestamp, an icon, and a copy to clipboard button for AI replies.
 *
 * @param {Object} props - The properties for the component.
 */
const ChatMessage = (props) => {
  const { id, createdAt, text, ai = false, selected } = props.message

  const [showCopyButton, setShowCopyButton] = useState(false)

  useEffect(() => {
    setShowCopyButton(false)
  }, [props.message])

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(text)
    setShowCopyButton(false)
  }

  return (
    <div key={id} className={`${ai && 'flex-row-reverse'} message`} onMouseEnter={() => setShowCopyButton(true)} onMouseLeave={() => setShowCopyButton(false)}>
      {
        selected === 'DALL·E' && ai ?
          <Image url={text} />
          :
          <div className='message__wrapper'>
            <ReactMarkdown className={`message__markdown ${ai ? 'text-left' : 'text-right'}`}
              children={text}
              remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || 'language-js')
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={atomDark} language={match[1]} PreTag="div" {...props}
                    />
                  ) : (<code className={className} {...props}>{children} </code>)
                }
              }} />

            <div className={`${ai ? 'text-left' : 'text-right'} message__createdAt`}>{moment(createdAt).fromNow()}</div>
          </div>}

      <div className="message__pic">
        {
          ai ? 
            <>
              <MdComputer />
              {showCopyButton && <MdContentCopy className="copy-button" onClick={handleCopyButtonClick} />}
            </>
            : <MdPersonOutline />
        }
      </div>
    </div>
  )
}

export default ChatMessage