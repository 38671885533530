import { ExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { ddbDocClient } from "./libs/ddbDocClient.js";

export const runUpdate_Address = async (
  tableName,
  email,
  reg_name,
  reg_surname,
  reg_identityNumber,
  reg_gsmNumber,
  reg_address,
  reg_city,
  reg_country,
  reg_zipCode,
  ship_contactName,
  ship_address,
  ship_city,
  ship_country,
  ship_zipCode,
  bill_contactName,
  bill_address,
  bill_city,
  bill_country,
  bill_zipCode
) => {
  const params = {
    Statement: `
      UPDATE ${tableName}
      SET reg_name = '${reg_name}', 
          reg_surname = '${reg_surname}', 
          reg_identityNumber = '${reg_identityNumber}', 
          reg_gsmNumber = '${reg_gsmNumber}', 
          reg_address = '${reg_address}', 
          reg_city = '${reg_city}', 
          reg_country = '${reg_country}', 
          reg_zipCode = '${reg_zipCode}', 
          ship_contactName = '${ship_contactName}', 
          ship_address = '${ship_address}', 
          ship_city = '${ship_city}', 
          ship_country = '${ship_country}', 
          ship_zipCode = '${ship_zipCode}', 
          bill_contactName = '${bill_contactName}', 
          bill_address = '${bill_address}', 
          bill_city = '${bill_city}', 
          bill_country = '${bill_country}', 
          bill_zipCode = '${bill_zipCode}'
      WHERE email = '${email}'
    `,
  };
  console.log("STATEMENT: " + params.Statement);
  try {
    await ddbDocClient.send(new ExecuteStatementCommand(params));
    console.log("Success. Item updated.");
    return "Run successfully"; // For unit tests.
  } catch (err) {
    console.error(err);
  }
};
