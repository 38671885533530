import { ExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { ddbDocClient } from "./libs/ddbDocClient.js";

/**
const date = new Date("2021-07-01T00:00:00Z");
const date = new Date();
const unixTimestamp = Math.floor(date.getTime() / 1000);
console.log(unixTimestamp); // Output: 1625068800
 */

export const runUpdate_LoadCredits = async (
  tableName,
  email,
  load_chatgptCredits,
  load_dalleCredits
) => {
  const params = {
    Statement: `
      UPDATE ${tableName}
      SET chatgptCredits = chatgptCredits + ${load_chatgptCredits}, dalleCredits = dalleCredits + ${load_dalleCredits}
      WHERE email = '${email}'
    `,
  };
  try {
    await ddbDocClient.send(new ExecuteStatementCommand(params));
    console.log("Success. Item updated.");
    return "Run successfully"; // For unit tests.
  } catch (err) {
    console.error(err);
  }
};
