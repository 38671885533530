import { useState } from "react";

export const options = [
  {
    label: "ChatGPT",
    tooltip:
      "ChatGPT is a language model developed by OpenAI that can generate human-like text.",
  },
  /*   {
    label: "Babbage",
    tooltip:
      "Babbage is a language model developed by OpenAI that can answer questions and perform tasks.",
  }, */
  {
    label: "DALL·E",
    tooltip:
      "DALL·E is an image generation model developed by OpenAI that can generate images from textual descriptions.",
  },
];

export const useSelected = () => {
  const [selected, setSelected] = useState(options[0].label);

  return { selected, setSelected };
};
