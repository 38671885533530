import { ExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { ddbDocClient } from "./libs/ddbDocClient";

/**
const date = new Date("2021-07-01T00:00:00Z");
const date = new Date();
const unixTimestamp = Math.floor(date.getTime() / 1000);
console.log(unixTimestamp); // Output: 1625068800
 */

export const runGet = async (tableName, email) => {
  const params = {
    Statement: `SELECT * FROM ${tableName} WHERE email = '${email}'`,
  };

  //console.log("STATEMENT: " + params.Statement);

  try {
    const data = await ddbDocClient.send(new ExecuteStatementCommand(params));
    for (let i = 0; i < data.Items.length; i++) {
      //console.log(
      //  "Success. The query return the following data. Item " + i,
      //  data.Items[i].email,
      //  data.Items[i].userId,
      //  data.Items[i].subscriptionType,
      //  data.Items[i].subscriptionStartDate,
      //  data.Items[i].subscriptionEndDate,
      //  data.Items[i].chatgptCredits,
      //  data.Items[i].dalleCredits,
      //  data.Items[i].paymentMethod,
      //  data.Items[i].paymentStatus,
      //  data.Items[i].subscriptionPlan,
      //  data.Items[i].subscriptionPrice,
      //  data.Items[i].renewalDate,
      //  data.Items[i].subscriptionStatus,
      //  data.Items[i].subscriptionDuration,
      //  data.Items[i].lastPaymentDate,
      //  data.Items[i].nextPaymentDate,
      //  data.Items[i].subscriptionId,
      //  data.Items[i].lastUpdatedDate
      //);
    }
    return data;
  } catch (err) {
    console.error(err);
  }
};
