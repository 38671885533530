import { useSelected } from "../components/SideBar_AI_RadioButtons";
import {
  useUpdatedChatGPTCredits,
  useUpdatedDalleCredits,
  useUpdatedSubscriptionPlan,
  paramChatGPTCredits,
  paramDalleCredits,
} from "../components/SideBar_CreditsUpdate";
import SideBar from "../components/SideBar";
import ChatView from "../components/ChatView";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { runGet } from "../services/dynamodb/get_ddb";
import { runInsert } from "../services/dynamodb/create_ddb";

const Home = ({ signOut, user }) => {
  const { selected, setSelected } = useSelected(); //To transfer "selected" and "setSelected" radio button value/func
  const { updatedChatGPTCredits, setUpdatedChatGPTCredits } =
    useUpdatedChatGPTCredits(); //To transfer value/func
  const { updatedDalleCredits, setUpdatedDalleCredits } =
    useUpdatedDalleCredits(); //To transfer value/func
  const { updatedSubscriptionPlan, setUpdatedSubscriptionPlan } =
    useUpdatedSubscriptionPlan(); //To transfer value/func

  const [userExists, setUserExists] = useState(false);
  const [user_email, setUserEmail] = useState(null);

  // eslint-disable-next-line
  const [chatGPTCredits, setChatGPTCredits] = useState(updatedChatGPTCredits);
  // eslint-disable-next-line
  const [dalleCredits, setDalleCredits] = useState(updatedDalleCredits);

  useEffect(() => {
    setChatGPTCredits(paramChatGPTCredits.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramChatGPTCredits.current]);

  useEffect(() => {
    setDalleCredits(paramDalleCredits.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramDalleCredits.current]);

  useEffect(() => {
    async function getUserData() {
      await Auth.currentAuthenticatedUser()
        .then((user_email) => {
          //console.log(user_email.signInUserSession.idToken.payload.email);
          setUserEmail(user_email.signInUserSession.idToken.payload.email);
          return user_email;
        })
        .catch((error) => {
          console.log(error);
        });

      //console.log("USER_EMAIL: " + user_email);

      if (user_email) {
        const result = await runGet(
          process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
          user_email
        );
        //console.log(result.Items[0].email.S);
        //console.log(result.Items[0].email.S.length);

        if (result.Items && result.Items.length > 0) {
          setUserExists(true);
        } else {
          try {
            const date = new Date();
            await runInsert(
              process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
              user_email,
              "userId",
              "Free",
              Math.floor(date.getTime() / 1000), //subscriptionStartDate
              Math.floor(date.getTime() / 1000), //subscriptionEndDate
              process.env.REACT_APP_SUBSCRIPTION_FREE_CREDITS_CHATGPT,
              process.env.REACT_APP_SUBSCRIPTION_FREE_CREDITS_DALLE,
              "paymentMethod",
              "paymentStatus",
              "Free",
              "0.00",
              Math.floor(date.getTime() / 1000), //renewalDate
              "active",
              "subscriptionDuration",
              Math.floor(date.getTime() / 1000), //lastPaymentDate
              Math.floor(date.getTime() / 1000), //nextPaymentDate
              "subscriptionId",
              Math.floor(date.getTime() / 1000), //lastUpdatedDate
              "", //reg_name
              "", //reg_surname
              "", //reg_identityNumber
              "", //reg_gsmNumber
              "", //reg_address
              "", //reg_city
              "", //reg_country
              "", //reg_zipCode
              "", //ship_contactName
              "", //ship_address
              "", //ship_city
              "", //ship_country
              "", //ship_zipCode
              "", //bill_contactName
              "", //bill_address
              "", //bill_city
              "", //bill_country
              "" //bill_zipCode
            );
            setUserExists(true); // Update userExists state to prevent duplicate inserts
            //*************************************** */
            paramChatGPTCredits.current =
              process.env.REACT_APP_SUBSCRIPTION_FREE_CREDITS_CHATGPT;
            paramDalleCredits.current =
              process.env.REACT_APP_SUBSCRIPTION_FREE_CREDITS_DALLE;

            setChatGPTCredits(
              process.env.REACT_APP_SUBSCRIPTION_FREE_CREDITS_CHATGPT
            );
            setDalleCredits(
              process.env.REACT_APP_SUBSCRIPTION_FREE_CREDITS_DALLE
            );
            //*************************************** */
          } catch (error) {
            if (error.message.includes("Duplicate")) {
              console.log("User already exists in database");
            } else {
              console.error(error);
            }
          }
        }
      }
    }
    getUserData();
  }, [userExists, user_email]);

  return (
    <div className="flex transition duration-500 ease-in-out">
      <SideBar
        signOut={signOut}
        selected={selected}
        setSelected={setSelected}
        user_email={user_email}
        updatedChatGPTCredits={updatedChatGPTCredits}
        updatedDalleCredits={updatedDalleCredits}
        updatedSubscriptionPlan={updatedSubscriptionPlan}
        setUpdatedChatGPTCredits={setUpdatedChatGPTCredits}
        setUpdatedDalleCredits={setUpdatedDalleCredits}
        setUpdatedSubscriptionPlan={setUpdatedSubscriptionPlan}
      />
      <ChatView selected={selected} user_email={user_email} />
    </div>
  );
};

export default Home;
