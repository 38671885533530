import { ExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { ddbDocClient } from "./libs/ddbDocClient.js";

/**
const date = new Date("2021-07-01T00:00:00Z");
const date = new Date();
const unixTimestamp = Math.floor(date.getTime() / 1000);
console.log(unixTimestamp); // Output: 1625068800
 */

export const runInsert = async (
  tableName,
  email,
  userId,
  subscriptionType,
  subscriptionStartDate,
  subscriptionEndDate,
  chatgptCredits,
  dalleCredits,
  paymentMethod,
  paymentStatus,
  subscriptionPlan,
  subscriptionPrice,
  renewalDate,
  subscriptionStatus,
  subscriptionDuration,
  lastPaymentDate,
  nextPaymentDate,
  subscriptionId,
  lastUpdatedDate,
  reg_name,
  reg_surname,
  reg_identityNumber,
  reg_gsmNumber,
  reg_address,
  reg_city,
  reg_country,
  reg_zipCode,
  ship_contactName,
  ship_address,
  ship_city,
  ship_country,
  ship_zipCode,
  bill_contactName,
  bill_address,
  bill_city,
  bill_country,
  bill_zipCode
) => {
  const params = {
    Statement: `INSERT INTO ${tableName} VALUE {
        'email': '${email}',
        'userId': '${userId}',
        'subscriptionType': '${subscriptionType}',
        'subscriptionStartDate': ${subscriptionStartDate},
        'subscriptionEndDate': ${subscriptionEndDate},
        'chatgptCredits': ${chatgptCredits},
        'dalleCredits': ${dalleCredits},
        'paymentMethod': '${paymentMethod}',
        'paymentStatus': '${paymentStatus}',
        'subscriptionPlan': '${subscriptionPlan}',
        'subscriptionPrice': '${subscriptionPrice}',
        'renewalDate': ${renewalDate},
        'subscriptionStatus': '${subscriptionStatus}',
        'subscriptionDuration': '${subscriptionDuration}',
        'lastPaymentDate': ${lastPaymentDate},
        'nextPaymentDate': ${nextPaymentDate},
        'subscriptionId': '${subscriptionId}',
        'lastUpdatedDate': ${lastUpdatedDate},
        'reg_name': '${reg_name}',
        'reg_surname': '${reg_surname}',
        'reg_identityNumber': '${reg_identityNumber}',
        'reg_gsmNumber': '${reg_gsmNumber}',
        'reg_address': '${reg_address}',
        'reg_city': '${reg_city}',
        'reg_country': '${reg_country}',
        'reg_zipCode': '${reg_zipCode}',
        'ship_contactName': '${ship_contactName}',
        'ship_address': '${ship_address}',
        'ship_city': '${ship_city}',
        'ship_country': '${ship_country}',
        'ship_zipCode': '${ship_zipCode}',
        'bill_contactName': '${bill_contactName}',
        'bill_address': '${bill_address}',
        'bill_city': '${bill_city}',
        'bill_country': '${bill_country}',
        'bill_zipCode': '${bill_zipCode}'
    }`,
  };

  //console.log("STATEMENT: " + params.Statement);

  try {
    await ddbDocClient.send(new ExecuteStatementCommand(params));
    console.log("Success. Item added.");
    return "Run successfully"; // For unit tests.
  } catch (err) {
    if (err.message.includes("Duplicate")) {
      console.log("User already exists in database");
    } else {
      console.error(err);
    }
  }
};
