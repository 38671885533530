import React, { useState, useContext } from "react";
import {
  MdClose,
  MdMenu,
  MdOutlineLogout,
  MdRefresh,
  MdOutlineAccountBox,
} from "react-icons/md";
import { GiBrain, GiOnTarget } from "react-icons/gi";
import { BsQuestionCircle } from "react-icons/bs";
import { ChatContext } from "../context/chatContext";
import DarkMode from "./DarkMode";
import { options } from "./SideBar_AI_RadioButtons";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { useEffect, useMemo } from "react";
import { useRef } from "react";
import { MdPayment } from "react-icons/md";
import axios from "axios";
import Modal from "react-modal";

import AddressFormModal from "./AddressFormModal";
import { runGet_Address } from "../services/dynamodb/get_address";
import { runGet } from "../services/dynamodb/get_ddb";
import { runUpdate_LoadCredits } from "../services/dynamodb/update_load_credits";

import {
  paramChatGPTCredits,
  paramDalleCredits,
} from "../components/SideBar_CreditsUpdate";

const SideBar = ({
  signOut,
  selected,
  setSelected,
  user_email,
  updatedChatGPTCredits,
  updatedDalleCredits,
  updatedSubscriptionPlan,
}) => {
  const [open, setOpen] = useState(false);
  const [, , clearMessages] = useContext(ChatContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [paymentPageUrl, setPaymentPageUrl] = useState(null);

  const [modalIsOpen_iyziLink, setModalIsOpen_iyziLink] = useState(false);
  const [paymentPageUrl_iyziLink, setPaymentPageUrl_iyziLink] = useState(null);
  const iframeRef_iyziLink = useRef(null);

  const [IyzicoToken, setIyzicoToken] = useState("");
  const [modalAddressIsOpen, setModalAddressIsOpen] = useState(false);

  // eslint-disable-next-line
  const [oneTimePaymentProduct, setOneTimePaymentProduct] = useState(null);

  // eslint-disable-next-line
  const [chatGPTCredits, setChatGPTCredits] = useState(updatedChatGPTCredits);
  // eslint-disable-next-line
  const [dalleCredits, setDalleCredits] = useState(updatedDalleCredits);

  useEffect(() => {
    setChatGPTCredits(paramChatGPTCredits.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramChatGPTCredits.current]);

  useEffect(() => {
    setDalleCredits(paramDalleCredits.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramDalleCredits.current]);

  const clearChat = () => clearMessages();

  const handleSignOut = () => {
    //clearChat();
    signOut();
  };

  const creditsMeaningTooltip = "One credit equals one AI-generated response.";

  const optionsOneTimePaymentProduct = [
    {
      label: process.env.REACT_APP_ONETIME_PAYMENT_1_LABEL,
      value: process.env.REACT_APP_ONETIME_PAYMENT_1_VALUE,
      tooltip: process.env.REACT_APP_ONETIME_PAYMENT_1_TOOLTIP,
    },
  ];

  /*   const optionsSubscriptions = [
    {
      label: process.env.REACT_APP_SUBSCRIPTION_1_LABEL,
      value: process.env.REACT_APP_SUBSCRIPTION_1_VALUE,
      tooltip: process.env.REACT_APP_SUBSCRIPTION_1_TOOLTIP,
    },
    {
      label: process.env.REACT_APP_SUBSCRIPTION_2_LABEL,
      value: process.env.REACT_APP_SUBSCRIPTION_2_VALUE,
      tooltip: process.env.REACT_APP_SUBSCRIPTION_2_TOOLTIP,
    },
    {
      label: process.env.REACT_APP_SUBSCRIPTION_3_LABEL,
      value: process.env.REACT_APP_SUBSCRIPTION_3_VALUE,
      tooltip: process.env.REACT_APP_SUBSCRIPTION_3_TOOLTIP,
    },
  ]; */

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxWidth: "600px",
      maxHeight: "80vh",
      overflow: "hidden",
      padding: "20px",
      borderRadius: "10px",
      border: "1px solid #ccc",
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
      position: "fixed",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  };

  const customStylesAddress = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxWidth: "600px",
      maxHeight: "80vh",
      overflow: "hidden",
      padding: "20px",
      borderRadius: "10px",
      border: "1px solid #ccc",
      boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
      position: "fixed",
      overflowY: "auto", // Enable vertical scroll bar
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  };

  const OneTimePaymentProduct = useMemo(async () => {
    if (
      modalAddressIsOpen ||
      !modalAddressIsOpen ||
      modalIsOpen ||
      !modalIsOpen
    ) {
      const address_data = await runGet_Address(
        process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
        user_email
      );

      //console.log("useMemo");

      return {
        locale: "en",
        conversationId: IyzicoToken, // use the token value,
        price: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_PRICE,
        basketId: "BB001",
        paymentGroup: "PRODUCT",
        buyer: {
          id: "BY001",
          name: address_data?.reg_name?.S || "",
          surname: address_data?.reg_surname?.S || "",
          identityNumber: address_data?.reg_identityNumber?.S || "",
          email: user_email,
          gsmNumber: address_data?.reg_gsmNumber?.S || "",
          registrationAddress: address_data?.reg_address?.S || "",
          city: address_data?.reg_city?.S || "",
          country: address_data?.reg_country?.S || "",
          zipCode: address_data?.reg_zipCode?.S || "",
        },
        shippingAddress: {
          address: address_data?.ship_address?.S || "",
          zipCode: address_data?.ship_zipCode?.S || "",
          contactName: address_data?.ship_contactName?.S || "",
          city: address_data?.ship_city?.S || "",
          country: address_data?.ship_country?.S || "",
        },
        billingAddress: {
          address: address_data?.bill_address?.S || "",
          zipCode: address_data?.bill_zipCode?.S || "",
          contactName: address_data?.bill_contactName?.S || "",
          city: address_data?.bill_city?.S || "",
          country: address_data?.bill_country?.S || "",
        },
        basketItems: [
          {
            id: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_ID,
            price: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_PRICE,
            name: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_NAME,
            category1: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_CATEGORY1,
            category2: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_CATEGORY2,
            itemType: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_ITEM_TYPE,
          },
        ],
        callbackUrl: process.env.REACT_APP_IYZICO_CALLBACK_URL,
        currency: "USD",
        paidPrice: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_PRICE,
      };
    }
  }, [user_email, IyzicoToken, modalAddressIsOpen, modalIsOpen]);

  const getOneTimePaymentProduct = async () => {
    const address_data = await runGet_Address(
      process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
      user_email
    );

    //console.log("getOneTimePaymentProduct");

    return {
      locale: "en",
      conversationId: IyzicoToken, // use the token value,
      price: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_PRICE,
      basketId: "BB001",
      paymentGroup: "PRODUCT",
      buyer: {
        id: "BY001",
        name: address_data?.reg_name?.S || "",
        surname: address_data?.reg_surname?.S || "",
        identityNumber: address_data?.reg_identityNumber?.S || "",
        email: user_email,
        gsmNumber: address_data?.reg_gsmNumber?.S || "",
        registrationAddress: address_data?.reg_address?.S || "",
        city: address_data?.reg_city?.S || "",
        country: address_data?.reg_country?.S || "",
        zipCode: address_data?.reg_zipCode?.S || "",
      },
      shippingAddress: {
        address: address_data?.ship_address?.S || "",
        zipCode: address_data?.ship_zipCode?.S || "",
        contactName: address_data?.ship_contactName?.S || "",
        city: address_data?.ship_city?.S || "",
        country: address_data?.ship_country?.S || "",
      },
      billingAddress: {
        address: address_data?.bill_address?.S || "",
        zipCode: address_data?.bill_zipCode?.S || "",
        contactName: address_data?.bill_contactName?.S || "",
        city: address_data?.bill_city?.S || "",
        country: address_data?.bill_country?.S || "",
      },
      basketItems: [
        {
          id: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_ID,
          price: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_PRICE,
          name: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_NAME,
          category1: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_CATEGORY1,
          category2: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_CATEGORY2,
          itemType: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_ITEM_TYPE,
        },
      ],
      callbackUrl: process.env.REACT_APP_IYZICO_CALLBACK_URL,
      currency: "USD",
      paidPrice: process.env.REACT_APP_IYZICO_ONETIME_PAYMENT_1_PRICE,
    };
  };

  const handleOneTimePayment = async () => {
    try {
      // check if address_data exists in the database
      const address_data = await runGet_Address(
        process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
        user_email
      );
      //console.log(address_data);
      if (
        !address_data ||
        !(address_data.reg_name.S.length > 0) ||
        !(address_data.reg_surname.S.length > 0) ||
        !(address_data.reg_address.S.length > 0) ||
        !(address_data.reg_city.S.length > 0) ||
        !(address_data.reg_country.S.length > 0) ||
        !(address_data.reg_zipCode.S.length > 0)
      ) {
        // if any field is empty
        setModalIsOpen(false); // close the payment page modal
        setModalAddressIsOpen(true); // open the address form modal
        return; // stop the function execution
      }
      // if reg_address exists, continue with the payment process

      console.log("payload:");
      //console.log(await OneTimePaymentProduct);
      const product = await getOneTimePaymentProduct();
      // Store the computed value of OneTimePaymentProduct in state
      setOneTimePaymentProduct(product);
      console.log(product);

      const options = {
        method: "POST",
        url: process.env.REACT_APP_IYZICO_INIT_CHECKOUT_URL,
        headers: {
          "Content-Type": "application/json",
        },
        data: product,
      };

      console.log(options);

      const response = await axios(options);

      console.log(response);
      console.log(response.data.token);
      const newIyzicoToken = response.data.token; // set the token value
      setIyzicoToken(newIyzicoToken); // update the state

      const paymentPageUrl = response.data.paymentPageUrl;

      setPaymentPageUrl(paymentPageUrl);

      setModalIsOpen(true);

      console.log("One-time payment initiated successfully");
    } catch (error) {
      console.error("Error initiating one-time payment:", error);
    }
    return handleOneTimePayment; // return the function
  };

  const handleAddressSubmit = async () => {
    try {
      // update address_data in the database with the form data
      // ...

      // close the address form modal and open the payment page modal
      setModalAddressIsOpen(false);
      setModalIsOpen(true);

      // continue with the payment process
      await handleOneTimePayment();
    } catch (error) {
      // handle errors
      console.log(error);
    }
  };

  useEffect(() => {
    if (!modalIsOpen && IyzicoToken) {
      //handleUpdateCredits(IyzicoToken);
      const handleUpdateCredits = async (token) => {
        try {
          const options = {
            method: "POST",
            url: process.env.REACT_APP_IYZICO_RETRIEVE_PAYMENT_RESULT,
            headers: {
              "Content-Type": "application/json",
            },
            data: { token },
          };
          const response = await axios(options);
          console.log("handleUpdateCredits:");
          console.log(response);
          const paymentStatus = response.data.paymentStatus; // get the payment status
          if (paymentStatus === "SUCCESS") {
            // update the user's credits in the database
            await runUpdate_LoadCredits(
              process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
              user_email,
              process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_CHATGPT,
              process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_DALLE
            );
            console.log("Credits updated successfully");
            //******************************** */
            const tableName = process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1;
            const result = await runGet(tableName, user_email);

            const chatgptCredits = Number(result.Items[0].chatgptCredits.N);
            const dalleCredits = Number(result.Items[0].dalleCredits.N);

            paramChatGPTCredits.current = chatgptCredits;
            paramDalleCredits.current = dalleCredits;

            setChatGPTCredits(chatgptCredits);
            setDalleCredits(dalleCredits);
            //******************************** */
          }
        } catch (error) {
          console.error("Error retrieving payment result:", error);
        }
      };
      handleUpdateCredits(IyzicoToken);
    }
  }, [modalIsOpen, IyzicoToken, user_email]);

  // Preload the payment page URL
  useEffect(() => {
    const fetchPaymentPageUrl = async () => {
      try {
        const options = {
          method: "POST",
          url: process.env.REACT_APP_IYZICO_INIT_CHECKOUT_URL,
          headers: {
            "Content-Type": "application/json",
          },
          data: await OneTimePaymentProduct,
        };
        //console.log(options.data);
        const response = await axios(options);
        const paymentPageUrl = response.data.paymentPageUrl;
        setPaymentPageUrl(paymentPageUrl);
        console.log("Payment page URL preloaded successfully");
      } catch (error) {
        console.error("Error preloading payment page URL:", error);
      }
    };
    fetchPaymentPageUrl();
  }, [OneTimePaymentProduct]);

  //iyziLink Integration
  //************************************************************************** */
  /*   const handleOneTimePayment_iyziLink = async () => {
    const paymentPageUrl_iyziLink = "https://iyzi.link/AJETfg";
    //const paymentPageUrl_iyziLink = "https://sandbox.iyzi.link/Whc";
    setPaymentPageUrl_iyziLink(paymentPageUrl_iyziLink);
    setModalIsOpen_iyziLink(true);

    return handleOneTimePayment_iyziLink; // return the function
  }; */

  const handleOneTimePayment_iyziLink = async () => {
    let paymentPageUrl_iyziLink = process.env.REACT_APP_IYZICO_IYZILINK_USD; //USD Price
    let paymentPageUrl_iyziLink_TRY = process.env.REACT_APP_IYZICO_IYZILINK_TRY; //TRY Price
    //let paymentPageUrl_iyziLink = "https://sandbox.iyzi.link/Whc"; //Sandbox
    const userIp = await axios
      .get("https://ipwho.is")
      //.get("https://api64.ipify.org?format=json")
      .then((response) => response.data.ip)
      .catch((error) => {
        console.error("Error retrieving user IP address:", error);
        return null;
      });

    if (userIp) {
      const ipWhoisResponse = await axios
        .get(`https://ipwho.is/${userIp}`)
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error retrieving IP whois information:", error);
          return null;
        });
      if (
        ipWhoisResponse &&
        ipWhoisResponse.success &&
        ipWhoisResponse.country_code === "TR"
      ) {
        paymentPageUrl_iyziLink = paymentPageUrl_iyziLink_TRY;
      }
    }

    setPaymentPageUrl_iyziLink(paymentPageUrl_iyziLink);
    setModalIsOpen_iyziLink(true);

    return handleOneTimePayment_iyziLink; // return the function
  };

  const handleModalClose_iyziLink = async () => {
    setModalIsOpen_iyziLink(false);
    console.log("Payment modal page closed.");
    setTimeout(async () => {
      if (
        window.confirm(
          'Was the payment successful? \n\nPlease click "OK" if yes, or "Cancel" otherwise. \n\nThank you.'
        )
      ) {
        setTimeout(async () => {
          //********************************
          await runUpdate_LoadCredits(
            process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
            user_email,
            process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_CHATGPT,
            process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_DALLE
          );
          console.log("Credits updated successfully");
          //********************************
          const tableName = process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1;
          const result = await runGet(tableName, user_email);

          const chatgptCredits = Number(result.Items[0].chatgptCredits.N);
          const dalleCredits = Number(result.Items[0].dalleCredits.N);

          paramChatGPTCredits.current = chatgptCredits;
          paramDalleCredits.current = dalleCredits;

          setChatGPTCredits(chatgptCredits);
          setDalleCredits(dalleCredits);
          //********************************
        }, 500); // wait for 0.5 seconds before executing the code
      }
    }, 200); // wait for 0.2 seconds before executing the code
  };

  /*   useEffect(() => {
    if (modalIsOpen_iyziLink && iframeRef_iyziLink.current) {
      const handleIframeNavigation = async () => {
        const currentUrl =
          iframeRef_iyziLink.current.contentWindow.location.href;
        console.log("currentUrl:");
        console.log(currentUrl);
        if (currentUrl.includes("/result/")) {
          //********************************
          await runUpdate_LoadCredits(
            process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1,
            user_email,
            process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_CHATGPT,
            process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_DALLE
          );
          console.log("Credits updated successfully");
          //********************************
          const tableName = process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1;
          const result = await runGet(tableName, user_email);

          const chatgptCredits = Number(result.Items[0].chatgptCredits.N);
          const dalleCredits = Number(result.Items[0].dalleCredits.N);

          paramChatGPTCredits.current = chatgptCredits;
          paramDalleCredits.current = dalleCredits;

          setChatGPTCredits(chatgptCredits);
          setDalleCredits(dalleCredits);
          //********************************
        }
      };
      handleIframeNavigation();
      iframeRef_iyziLink.current.contentWindow.addEventListener(
        "load",
        handleIframeNavigation
      );
      const iframeWindow = iframeRef_iyziLink.current.contentWindow;
      iframeWindow.addEventListener("load", handleIframeNavigation);
      return () => {
        iframeWindow.removeEventListener("load", handleIframeNavigation);
      };
    }
  }, [user_email, modalIsOpen_iyziLink]); */
  //************************************************************************** */

  return (
    <section className={` ${open ? "w-72" : "w-10 "} sidebar`}>
      <div className="sidebar__app-bar">
        <div className={`sidebar__app-logo ${!open && "scale-0 hidden"}`}>
          <span className="w-8 h-8 text-2xl">
            <GiBrain />
          </span>
        </div>
        <h1 className={`sidebar__app-title ${!open && "scale-0 hidden"}`}>
          Brainbook.link
        </h1>
        <div className="sidebar__btn-close" onClick={() => setOpen(!open)}>
          {open ? (
            <MdClose className="sidebar__btn-icon" />
          ) : (
            <MdMenu className="sidebar__btn-icon" />
          )}
        </div>
      </div>

      <div className="nav">
        <span className="nav__item bg-light-white" style={{ height: "auto" }}>
          <div className="nav__icons" onClick={() => setOpen(true)}>
            <MdOutlineAccountBox />
          </div>
          <div className={`${!open && "hidden"}`}>
            <h1 style={{ marginBottom: "4px", fontWeight: "bold" }}>
              My Account
            </h1>
            <p>
              <span
                style={{
                  maxWidth: "150px",
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {user_email}
              </span>
            </p>
            {/*             <p>
              Subscription Plan:{" "}
              {updatedSubscriptionPlan.current
                ? updatedSubscriptionPlan.current
                : "-"}
            </p> */}
            <p>
              ChatGPT{" "}
              <span
                style={{ textDecoration: "underline dashed" }}
                data-tooltip-id="credits"
                data-tooltip-content={creditsMeaningTooltip}
              >
                credits
              </span>{" "}
              left:{" "}
              {updatedChatGPTCredits.current
                ? updatedChatGPTCredits.current
                : "-"}
            </p>
            <Tooltip id="credits" style={{ maxWidth: "70%" }} />
            <p>
              DALL·E{" "}
              <span
                style={{ textDecoration: "underline dashed" }}
                data-tooltip-id="credits"
                data-tooltip-content={creditsMeaningTooltip}
              >
                credits
              </span>{" "}
              left:{" "}
              {updatedDalleCredits.current ? updatedDalleCredits.current : "-"}
            </p>
            <Tooltip id="credits" style={{ maxWidth: "70%", zIndex: 9999 }} />
          </div>
        </span>
      </div>

      <div className="nav">
        <span className="nav__item bg-light-white" style={{ height: "auto" }}>
          <div className="nav__icons" onClick={() => setOpen(true)}>
            <GiOnTarget />
          </div>
          <div className={`${!open && "hidden"}`}>
            <h1 style={{ marginBottom: "4px", fontWeight: "bold" }}>
              AI Models
            </h1>
            {options.map((option) => (
              <div key={option.label} style={{ marginBottom: "4px" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="radio"
                    value={option.label}
                    checked={selected === option.label}
                    onChange={(e) => setSelected(e.target.value)}
                    style={{ marginRight: "8px" }}
                  />
                  <span>{option.label}</span>
                  <BsQuestionCircle
                    className="nav__icons"
                    style={{
                      marginLeft: "8px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    data-tooltip-id={option.label}
                    data-tooltip-content={option.tooltip}
                  />
                  <Tooltip
                    id={option.label}
                    style={{ maxWidth: "70%", zIndex: 9999 }}
                  />
                </label>
              </div>
            ))}
          </div>
        </span>
      </div>

      {paramChatGPTCredits.current <
        process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_CHATGPT * 0.5 ||
      paramDalleCredits.current <
        process.env.REACT_APP_ONETIME_PAYMENT_1_LOAD_CREDITS_DALLE * 0.5 ? (
        <div className="nav">
          <span className="nav__item bg-light-white" style={{ height: "auto" }}>
            <div className="nav__icons" onClick={() => setOpen(true)}>
              <MdPayment />
            </div>
            <div className={`${!open && "hidden"}`}>
              <h1
                style={{
                  marginBottom: "4px",
                  fontWeight: "bold",
                  color: "orange",
                }}
              >
                {process.env.REACT_APP_TEXT_LOAD_CREDITS}
              </h1>
              {optionsOneTimePaymentProduct.map((option) => (
                <div key={option.value} style={{ marginBottom: "4px" }}>
                  <h1
                    //onClick={() => handleOneTimePayment(option.value)}
                    onClick={() => handleOneTimePayment_iyziLink(option.value)}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textDecoration: "underline",
                      transition: "color 0.3s ease-in-out",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "orange")}
                    onMouseLeave={(e) => (e.target.style.color = "white")}
                  >
                    {option.label}{" "}
                  </h1>

                  <label style={{ fontSize: "13px" }}>{option.tooltip}</label>
                </div>
              ))}
            </div>
          </span>
        </div>
      ) : null}

      {/* <div className="nav">
        <span className="nav__item bg-light-white" style={{ height: "auto" }}>
          <div className="nav__icons" onClick={() => setOpen(true)}>
            <MdPayment />
          </div>
          <div className={`${!open && "hidden"}`}>
            <h1
              style={{
                marginBottom: "4px",
                fontWeight: "bold",
                color: "orange",
              }}
            >
              Subscription Plans
            </h1>
            {optionsSubscriptions.map((option) => (
              <div key={option.value} style={{ marginBottom: "4px" }}>
                <h1
                  onClick={() => handleSubscription(option.value)}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: "15px",
                    textDecoration: "underline",
                    transition: "color 0.3s ease-in-out",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "orange")}
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  {option.label}{" "}
                </h1>

                <label style={{ fontSize: "13px" }}>{option.tooltip}</label>
              </div>
            ))}
          </div>
        </span>
      </div> */}

      <div className="nav">
        <span
          className="nav__item  bg-light-white"
          onClick={() => {
            if (
              window.confirm("Are you sure you want to clear ALL the messages?")
            ) {
              clearChat();
            }
          }}
        >
          <div className="nav__icons">
            <MdRefresh />
          </div>
          <h1 className={`${!open && "hidden"}`}>Clear Screen</h1>
        </span>
      </div>

      <div className="nav__bottom">
        <DarkMode open={open} />
        <div className="nav">
          <span className="nav__item" onClick={handleSignOut}>
            <div className="nav__icons">
              <MdOutlineLogout />
            </div>
            <h1 className={`${!open && "hidden"}`}>Sign out</h1>
          </span>
        </div>
      </div>

      <Modal
        isOpen={modalAddressIsOpen}
        style={customStylesAddress}
        onRequestClose={() => setModalAddressIsOpen(false)}
      >
        <div style={{ overflowY: "auto" }}>
          <button
            className="close-button"
            onClick={() => setModalAddressIsOpen(false)}
          >
            X
          </button>
          <AddressFormModal
            user_email={user_email}
            onAddressSubmit={handleAddressSubmit}
          />
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        <div>
          <h2>One-time payment</h2>
          {paymentPageUrl && (
            <iframe
              title="payment-page"
              src={paymentPageUrl}
              style={{ width: "100%", height: "500px" }}
            />
          )}
          <button
            className="close-button"
            onClick={() => setModalIsOpen(false)}
          >
            X
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen_iyziLink}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleModalClose_iyziLink}
      >
        <div>
          <h2>One-time payment</h2>
          {paymentPageUrl_iyziLink && (
            <iframe
              title="payment-page"
              src={paymentPageUrl_iyziLink}
              style={{ width: "100%", height: "500px" }}
              ref={iframeRef_iyziLink}
            />
          )}
          <button
            className="close-button"
            onClick={() => {
              handleModalClose_iyziLink();
            }}
          >
            X
          </button>
        </div>
      </Modal>
    </section>
  );
};

export default SideBar;
