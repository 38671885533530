// Create the DynamoDB service client module using ES6 syntax.
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";

// Set the AWS Region.
export const REGION = "us-east-2"; // For example, "us-east-2".

// Create an Amazon DynamoDB service client object.
export const ddbClient = new DynamoDBClient({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_DYNAMODB_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_DYNAMODB_SECRET_ACCESS_KEY,
  },
});

/*
Table Name: process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME_1

Partition Key: email (String)

Sort Key: None

Attributes:
- email (String): The email address of the user.
- userId (String): The unique identifier for the user in your system.
- subscriptionType (String): The current subscription type of the user, either "free", "monthly" or "yearly".
- subscriptionStartDate (Number): The start date of the current subscription in Unix time format.
- subscriptionEndDate (Number): The end date of the current subscription in Unix time format.
- chatgptCredits (Number): The number of ChatGPT credits the user has.
- dalleCredits (Number): The number of DallE credits the user has.
- paymentMethod (String): The method of payment used for the subscription (e.g. credit card, PayPal, etc.).
- paymentStatus (String): The status of the user's payment for the subscription (e.g. paid, overdue, cancelled).
- subscriptionPlan (String): The name of the subscription plan (e.g. Basic, Premium, etc.).
- subscriptionPrice (String): The price of the subscription plan.
- renewalDate (Number): The date when the subscription will automatically renew.
- subscriptionStatus (String): The status of the subscription (e.g. active, cancelled, paused).
- subscriptionDuration (String): The duration of the subscription (e.g. 1 month, 3 months, 1 year).
- lastPaymentDate (Number): The date of the user's last payment.
- nextPaymentDate (Number): The date when the user's next payment is due.
- subscriptionId (String): The unique identifier for the user's subscription in your system.
- lastUpdatedDate (Number): The date when the subscription information was last updated.

*/

/**
 * 
 {
 "email": "",
 "userId": "",
 "subscriptionType": "",
 "subscriptionStartDate": 0,
 "subscriptionEndDate": 0,
 "chatgptCredits": 0,
 "dalleCredits": 0,
 "paymentMethod": "",
 "paymentStatus": "",
 "subscriptionPlan": "",
 "subscriptionPrice": "",
 "renewalDate": 0,
 "subscriptionStatus": "",
 "subscriptionDuration": "",
 "lastPaymentDate": 0,
 "nextPaymentDate": 0,
 "subscriptionId": "",
 "lastUpdatedDate": 0
}

 */
