//https://ui.docs.amplify.aws/react/connected-components/authenticator/customization

import React, { useState } from "react";
import Home from "./pages/Home";
import { ChatContextProvider } from "./context/chatContext";
import { Authenticator, useTheme, View, Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { GiBrain } from "react-icons/gi";
import Modal from "react-modal";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

Modal.setAppElement("#root");

I18n.putVocabularies(translations);
I18n.setLanguage("en");

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div className="flex justify-center items-center">
          <div className="bg-gray-200 rounded-lg p-2" style={{ color: "gray" }}>
            <span className="text-7xl">
              <GiBrain className="ml-2" />
            </span>
          </div>
        </div>
        <div
          className="flex justify-center items-center"
          style={{ color: "gray" }}
        >
          <span className="text-5xl">Brainbook</span>
        </div>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();
    const [isOpenPrivacy, setIsOpenPrivacy] = useState(false);

    const openModalPrivacy = () => {
      setIsOpenPrivacy(true);
    };

    const closeModalPrivacy = () => {
      setIsOpenPrivacy(false);
    };

    const [isOpenTerms, setIsOpenTerms] = useState(false);

    const openModalTerms = () => {
      setIsOpenTerms(true);
    };

    const closeModalTerms = () => {
      setIsOpenTerms(false);
    };

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text
          textAlign="justify"
          color={tokens.colors.neutral[80]}
          marginBottom="0.5em"
        >
          Welcome to Brainbook.link where the power of artificial intelligence
          meets the boundless potential of human imagination. Our platform
          features cutting-edge technologies such as ChatGPT and Dall·E,
          enabling you to explore a world of possibilities, unlock your full
          potential, create stunning visuals that were once only possible in
          your wildest dreams.
        </Text>
        <Text
          textAlign="justify"
          color={tokens.colors.neutral[80]}
          marginBottom="0.5em"
        >
          With Brainbook.link the possibilities are endless. Whether you're
          seeking inspiration, knowledge, or answers to your questions, our
          AI-powered tools are here to help you unlock your full potential. Join
          our community of thinkers, creators, and innovators today and discover
          a world of limitless possibilities.
        </Text>
        <Text
          textAlign="justify"
          color={tokens.colors.neutral[80]}
          marginBottom="1.5em"
        >
          Already registered? Sign in now or create your account today!
        </Text>
        <Text color={tokens.colors.neutral[80]}>
          &copy; Brainbook.link. All Rights Reserved
          <br />
          <button
            onClick={openModalTerms}
            className="amplify-button amplify-button--link amplify-button--small"
            style={{ fontWeight: "var(--amplify-font-weights-normal)" }}
          >
            Terms and Conditions
          </button>{" "}
          |{" "}
          <button
            onClick={openModalPrivacy}
            className="amplify-button amplify-button--link amplify-button--small"
            style={{ fontWeight: "var(--amplify-font-weights-normal)" }}
          >
            Privacy Policy
          </button>
          <TermsAndConditions
            isOpen={isOpenTerms}
            closeModal={closeModalTerms}
          />
          <PrivacyPolicy
            isOpen={isOpenPrivacy}
            closeModal={closeModalPrivacy}
          />
        </Text>
      </View>
    );
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
    password: {
      placeholder: "Enter your password",
    },
  },
  signUp: {
    username: {
      label: "Username",
      placeholder: "Enter your email",
      order: 1,
    },
    password: {
      label: "Password",
      placeholder: "Enter your password",
      order: 2,
    },
    confirm_password: {
      label: "Confirm password",
      placeholder: "Please confirm your password",
      order: 3,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your password",
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email",
    },
  },
};

const App = () => {
  return (
    <ChatContextProvider>
      <Authenticator
        components={components}
        socialProviders={["google"]}
        formFields={formFields}
      >
        {({ signOut, user }) => <Home signOut={signOut} user={user} />}
      </Authenticator>
    </ChatContextProvider>
  );
};

export default App;
